import React, { useEffect, useState } from "react";
import bg from "../Assets/bg3.png";
import ProductCard from "../Cards/ProductCard";
import Card from "../Cards/BlogCards";
import { NavLink, useParams } from "react-router-dom";
import { fetchProductDetailsData } from "../Api/Api";
import Loader from "../Loader/Loader";

const ProductDetail = () => {
  const { productId } = useParams();

  const [data, setData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchProductDetailsData(productId);

        setData(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching product details data:", error);
      }
    };

    loadData(productId);
  }, [productId]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <div
        className=" bg-[#F6F9FC]     bg-right-top  bg-no-repeat md:p-8"
        style={{ backgroundImage: `url(${bg})`, backgroundSize: 600 }}
      >
        <div className="w-[85%] mx-auto md:flex justify-between py-8">
          <div className="my-auto md:mr-6 md:w-[50%]">
            <h3 className="text-2xl text-[#70090A] uppercase">
              {data?.product?.title}
            </h3>
            <p className="text-sm py-2">
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.product?.description,
                }}
              />
            </p>
          </div>

          <div>
            <img
              src={data?.product?.image_url}
              alt={data?.product?.image_attribute}
              className="md:w-[450px] w-full md:mt-0 mt-4 object-contain mx-auto h-[350px]"
            />
          </div>
        </div>
      </div>

      <div className="w-[85%] mx-auto py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-10">
          {data?.product?.galleries?.map((card, index) => (
            <ProductCard
              key={index}
              image={card.image_url}
              title={card.title}
              description={card.description}
              link={card.product_enquiry_link}
              mob_link={card.product_enquiry_link_mobile}
            />
          ))}
        </div>
      </div>
      {data?.blogs?.length > 0 && (
        <div className="w-[85%] mx-auto my-10">
          <p className="text-center text-lg py-2 font-bold">Our</p>
          <h2 className="text-[#6C0406] text-center uppercase text-2xl mb-4 font-bold">
            BLOGS
          </h2>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 ">
            {data?.blogs?.map((item) => (
              <Card
                image={item.image_url}
                title={item.title}
                date={item.posted_date_formated}
                link={item.short_url}
              />
            ))}
          </div>
          <div className="text-end w-full mt-4">
            <NavLink
              to="/blogs"
              className="relative inline-flex items-center justify-start px-4 py-2 my-3 overflow-hidden font-medium transition-all bg-[#F6C47E] rounded hover:bg-[#F6C47E] group active:scale-95 active:opacity-90"
            >
              <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#D8A253] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
              <span className="relative w-full text-left text-[#6C0406] transition-colors duration-300 ease-in-out group-hover:text-white">
                <span> View All</span>
              </span>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetail;
