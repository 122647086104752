import React, { useEffect, useState } from "react";
import bg from "../Assets/bg3.png";
import { NavLink } from "react-router-dom";
import Card from "../Cards/BlogCards";
import { fetchProductData } from "../Api/Api";
import MetaHelmet from "../Metadata/MetaData";
import Loader from "../Loader/Loader";

const Products = () => {
  const [data, setData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)
      try {
        const result = await fetchProductData();

        setData(result);
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error("Error fetching product data:", error);
      }
    };

    loadData();
  }, []);
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      {data && <MetaHelmet metaData={data.meta_data} />}

      {data?.productContent && (
        <div
          className=" bg-[#F6F9FC]     bg-right-top  bg-no-repeat md:p-8"
          style={{ backgroundImage: `url(${bg})`, backgroundSize: 600 }}
        >
          <div className="w-[85%] mx-auto md:flex justify-between py-8">
            <div className="my-auto md:mr-6 md:w-[50%]">
              <h3 className="text-2xl text-[#70090A] uppercase">
                {data?.productContent?.title}
              </h3>
              <p className="text-sm py-2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.productContent?.description,
                  }}
                />
              </p>
            </div>

            <div>
              <img
                src={data?.productContent?.image_url}
                alt={data?.productContent?.image_attribute}
                className="md:w-[450px] w-full md:mt-0 mt-4 object-contain mx-auto h-[350px]"
              />
            </div>
          </div>
        </div>
      )}
      {data?.products?.length > 0 && (
        <div className="w-[85%] mx-auto py-10">
          <p className="font-bold text-base text-center py-8">
            <div
              dangerouslySetInnerHTML={{
                __html: data?.productContent?.detailed_description,
              }}
            />
          </p>

          <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-6">
  {data?.products?.map((product, index) => (
    <NavLink to={`/product/${product.short_url}`} key={index} className="block group">
      <div className="relative h-[350px]">
        <img
          src={product.image_url}
          alt={product.title}
          className="object-cover w-full h-full"
        />
        <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent text-white text-center py-2 transition-opacity duration-300  opacity-100">
          <span className="text-lg font-bold underline">{product.title}</span>
        </div>
      </div>
    </NavLink>
  ))}
</div>

          {/* <div className="py-3">
            {data?.products?.map((product, index) => {
              if (index % 2 !== 0) return null;

              return (
                <div
                  className={`flex ${index !== 0 ? "py-2" : ""}`}
                  key={product.id}
                >
                  <div
                    className={`relative ${
                      index % 4 === 0 ? "w-[40%] mr-2" : "w-[60%] mr-2"
                    } h-[300px]`}
                  >
                    <img
                      src={product.image_url}
                      alt={product.title}
                      className="object-cover w-full h-full"
                    />
                    <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent text-white text-center py-2">
                      <button className="text-lg font-bold underline">
                        <NavLink to={`/product/${product.short_url}`}>
                          {product.title}
                        </NavLink>
                      </button>
                    </div>
                  </div>

                  {data?.products[index + 1] && (
                    <div
                      className={`relative ${
                        index % 4 === 0 ? "w-[60%]" : "w-[40%]"
                      } h-[300px]`}
                    >
                      <img
                        src={data?.products[index + 1].image_url}
                        alt={data?.products[index + 1].title}
                        className="object-cover w-full h-full"
                      />
                      <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent text-white text-center py-2">
                        <button className="text-lg font-bold underline">
                          <NavLink
                            to={`/product/${
                              data?.products[index + 1].short_url
                            }`}
                          >
                            {data?.products[index + 1].title}
                          </NavLink>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div> */}
        </div>
      )}
      {data?.blogs?.length > 0 && (
        <div className="w-[85%] mx-auto my-10">
          <p className="text-center text-lg py-2 font-bold">Our</p>
          <h2 className="text-[#6C0406] text-center uppercase text-2xl mb-4 font-bold">
            BLOGS
          </h2>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 ">
            {data?.blogs?.map((item) => (
              <Card
                image={item.image_url}
                title={item.title}
                date={item.posted_date_formated}
                link={item.short_url}
              />
            ))}
          </div>
          <div className="text-end w-full mt-4">
            <NavLink
              to="/blogs"
              className="relative inline-flex items-center justify-start px-4 py-2 my-3 overflow-hidden font-medium transition-all bg-[#F6C47E] rounded hover:bg-[#F6C47E] group active:scale-95 active:opacity-90"
            >
              <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#D8A253] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
              <span className="relative w-full text-left text-[#6C0406] transition-colors duration-300 ease-in-out group-hover:text-white">
                <span> View All</span>
              </span>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
