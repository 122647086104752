import React, { useEffect, useState } from "react";
import bg2 from "../Assets/bg3.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { FaEnvelope, FaLocationDot, FaPhone } from "react-icons/fa6";
import { fetchContactData, sendFormData } from "../Api/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MetaHelmet from "../Metadata/MetaData";
import Loader from "../Loader/Loader";

const Contact = () => {
  const [data, setData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)
      try {
        const result = await fetchContactData();

        setData(result);
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error("Error fetching contact data:", error);
      }
    };

    loadData();
  }, []);

  // Validation schemas
  const form1ValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[A-Za-z]+$/, "First Name should contain only letters")
      .required("First Name is required"),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      )
      .required("Email is required"),
    mobileNumber: Yup.string().required("Mobile Number is required"),
  });

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      {data && <MetaHelmet metaData={data.meta_data} />}
      <div className="lg:flex">
        {data?.contact && (
          <div
            className=" bg-white bg-left-top  bg-no-repeat lg:w-1/2  py-14"
            style={{
              backgroundImage: `url(${data?.contact?.image_url})`,
              backgroundSize: 500,
            }}
          >
            <div className="lg:w-[70%] w-[85%] mx-auto my-10 pb-6">
              <h3 className="text-2xl font-bold text-[#70090A] uppercase">
                {data?.contact?.title}
              </h3>
              <p className="text-sm"> {data?.contact?.sub_title}</p>
              <h2 className="text-3xl py-4 text-black  ">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.contact?.description,
                  }}
                />
              </h2>
            </div>
          </div>
        )}
        {data?.contact && (
          <div
            className=" bg-white bg-right-top  bg-no-repeat lg:w-1/2  py-14"
            style={{ backgroundImage: `url(${bg2})`, backgroundSize: 500 }}
          >
            <div className="  w-[85%] mx-auto lg:w-auto  mt-16 lg:pr-[15%]">
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  mobileNumber: "",
                  service: "",
                  message: "",
                }}
                validationSchema={form1ValidationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    console.log("Form 1 values:", values);

                    // Send the form data to the server
                    const result = await sendFormData(values);

                    // Display success toast
                    toast.success(result);

                    // Reset the form
                    resetForm();
                  } catch (error) {
                    // Display error toast
                    toast.error("Form submission failed!");
                    console.error("Form submission error:", error);
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form className="space-y-4">
                    <div className="flex flex-col md:flex-row md:space-x-4">
                      <label className="block flex-1">
                        <span className="text-xs">
                          FIRST NAME <span className="text-red-600">*</span>
                        </span>
                        <Field
                          type="text"
                          name="firstName"
                          placeholder="Enter your first name..."
                          className="mt-1 block focus:outline-none text-xs w-full   py-2 border-b bg-transparent border-gray-500"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </label>
                      <label className="block flex-1 mt-4 md:mt-0">
                        <span className="text-xs">LAST NAME</span>
                        <Field
                          type="text"
                          name="lastName"
                          placeholder="Enter your last name..."
                          className="mt-1 block focus:outline-none w-full text-xs   py-2 border-b bg-transparent border-gray-500"
                        />
                      </label>
                    </div>
                    <div className="flex flex-col md:flex-row md:space-x-4">
                      <label className="block flex-1">
                        <span className="text-xs">
                          EMAIL <span className="text-red-600">*</span>
                        </span>
                        <Field
                          type="email"
                          name="email"
                          placeholder="Enter your email..."
                          className="mt-1 block focus:outline-none text-xs w-full   py-2 border-b bg-transparent border-gray-500"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </label>
                      <label className="block flex-1 mt-4 md:mt-0">
                        <span className="text-xs">
                          MOBILE NUMBER <span className="text-red-600">*</span>
                        </span>
                        <PhoneInput
                          international
                          defaultCountry="IN"
                          countryCallingCodeEditable={false}
                          className={`mt-1 block focus:outline-none w-full text-xs   py-2 border-b  bg-transparent border-gray-500  `}
                          value={values.mobileNumber}
                          onChange={(value) =>
                            setFieldValue("mobileNumber", value)
                          }
                          placeholder="Enter your number..."
                        />
                        {values.mobileNumber &&
                          !isValidPhoneNumber(values.mobileNumber) && (
                            <div className="text-red-500 text-xs mt-1">
                              Invalid phone number
                            </div>
                          )}
                        <ErrorMessage
                          name="mobileNumber"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </label>
                    </div>

                    <label className="block">
                      <span className="text-xs">SERVICE</span>
                      <Field
                        type="text"
                        name="service"
                        placeholder="Enter your service..."
                        className="mt-1 block focus:outline-none w-full text-xs   py-2 border-b bg-transparent border-gray-500"
                      />
                    </label>
                    <label className="block">
                      <span className="text-xs">MESSAGE</span>
                      <Field
                        as="textarea"
                        name="message"
                        placeholder="Enter your message..."
                        className="mt-1 text-xs block focus:outline-none w-full   py-2 border-b bg-transparent border-gray-500"
                        rows="1"
                      />
                    </label>
                    <div className="text-end">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="relative inline-flex items-center justify-start px-4 py-2 my-3 overflow-hidden font-medium transition-all bg-[#F6C47E] rounded hover:bg-[#F6C47E] group active:scale-95 active:opacity-90"
                      >
                        <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#D8A253] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
                        <span className="relative w-full text-left text-[#6C0406] transition-colors duration-300 ease-in-out group-hover:text-white">
                          {isSubmitting ? "Sending..." : "SUBMIT"}
                        </span>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
      {data?.contact && (
        <div className="w-[85%] mx-auto py-10">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="flex flex-col">
              <div className=" bg-[#F9F9F9] rounded-full p-2 w-max">
                <FaEnvelope className="  text-[#F98083]  " />
              </div>

              <h3 className="text-base  mt-3">Email Support</h3>
              <p className="text-sm py-1">{data?.contact?.email_title}</p>
              <a href={`mailto:${data?.contact?.email_id}`}>
                <span className="text-sm text-[#8C1A1C] m-0">
                  {" "}
                  {data?.contact?.email_id}
                </span>
              </a>
            </div>

            <div className="flex flex-col">
              <div className=" bg-[#F9F9F9] rounded-full p-2 w-max">
                <FaLocationDot className="  text-[#F98083]  " />
              </div>

              <h3 className="text-base  mt-3">Visit Our Office</h3>
              <p className="text-sm py-1">{data?.contact?.address_title}</p>
              <p className="text-sm text-[#8C1A1C] m-0">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.contact?.address,
                  }}
                />
              </p>
            </div>

            <div className="flex flex-col">
              <div className=" bg-[#F9F9F9] rounded-full p-2 w-max">
                <FaPhone className="  text-[#F98083]  " />
              </div>

              <h3 className="text-base  mt-3">Call Us Directly</h3>
              <p className="text-sm py-1">{data?.contact?.phone_title}</p>
              <a href={`tel:${data?.contact?.phone_number}`}>
                <span className="text-sm text-[#8C1A1C] m-0">
                  {" "}
                  {data?.contact?.phone_number}
                </span>
              </a>
            </div>
          </div>
        </div>
      )}
      {data?.contact?.google_map && (
        <div className="w-full ">
          <iframe
            src={data?.contact?.google_map}
            width="100%"
            height="450"
            style={{ border: "0" }}
            allowFullScreen
            title="map"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      )}

      {/* Toast Container */}
      <ToastContainer />
    </div>
  );
};

export default Contact;
