import Footer from "./Footer/Footer";
import NestedNav from "./Navbar/Navbar";
import TopNav from "./Navbar/TopNav";
import About from "./Pages/About";
import Home from "./Pages/Home";
import { Route, Routes } from "react-router-dom";
import Projects from "./Pages/Projects";
import Services from "./Pages/Services";
import PhotoGallery from "./Pages/PhotoGallery";
import VideoGallery from "./Pages/VideoGallery";
import Products from "./Pages/Products";
import SpecialWorks from "./Pages/SpecialWorks";
import Blog from "./Pages/Blog";
import BlogDetail from "./Pages/BlogDetail";
import Contact from "./Pages/Contact";
import phone from "./Assets/phone.png";
import wapp from "./Assets/whatsapp.png";
import ServiceDetails from "./Pages/ServiceDetails";
import ScrollToTop from "./ScrollTop/ScrollTop";
import ProductDetail from "./Pages/ProductDetails";
import { useEffect, useState } from "react";
import { fetchSiteData } from "./Api/Api";
import NotFound from "./NotFound/NotFound";

function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await fetchSiteData();

        setData(result);
      } catch (error) {
        console.error("Error fetching site info data:", error);
      }
    };

    loadData();
  }, []);

  const footerData = {
    bg: data?.site_info?.footer_bg_url,
    logo: data?.site_info?.logo_url,
    design: data?.site_info?.designed_by_text,
    copy: data?.site_info?.copyright_text,
    phone: data?.site_info?.phone_number,
    email: data?.site_info?.email_id,
    whatsapp: data?.site_info?.whatsapp_number,
    utube: data?.site_info?.youtube_url,
    x: data?.site_info?.twitter_url,
    fb: data?.site_info?.facebook_url,
    insta: data?.site_info?.instagram_url,
    menus: data?.serviceMenus,
  };

  return (
    <div>
      <div className="fixed right-3 z-[999] bottom-10 ">
        <a href={`tel:${footerData.phone}`}>
          <img src={phone} alt="" />
        </a>
        <a
          href={`https://web.whatsapp.com/send?phone=${data?.site_info?.whatsapp_number}`}
        >
          <img src={wapp} alt="WhatsApp" />
        </a>
      </div>
      <div className="  w-full   bg-[#490001]   shadow-md z-50">
        <TopNav data={footerData} tag={data?.extraMetaTag}/>
      </div>
      <div className="  w-full   bg-[#70090A] shadow-md relative z-50">
        <div className="w-[88%] mx-auto">
          <NestedNav />
        </div>
      </div>
      <ScrollToTop tag={data?.extraMetaTag}/>
      <Routes>
        <Route
          path="/"
          element={<Home bg={data?.site_info?.what_we_offer_bg_url} />}
        />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/services/:serviceId" element={<Services />} />
        <Route path="/photo-gallery" element={<PhotoGallery />} />
        <Route path="/video-gallery" element={<VideoGallery />} />
        <Route path="/products" element={<Products />} />
        <Route path="/special-works" element={<SpecialWorks />} />
        <Route path="/product/:productId" element={<ProductDetail />} />
        <Route path="/projects/:typeId" element={<ServiceDetails />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/blog/:blogId" element={<BlogDetail />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {footerData.bg && <Footer data={footerData} tag={data?.extraMetaTag}/>}
    </div>
  );
}

export default App;
