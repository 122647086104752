import React, { useEffect, useState } from "react";
import { Navbar, Nav, Dropdown } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "./Navbar.css";
import logo from "../Assets/logo.png";
import { NavLink, useLocation } from "react-router-dom";
import { fetchMenuData } from "../Api/Api";

const renderMenuItems = (
  items,
  path,
  setMenuOpen,
  activeDropdown,
  setActiveDropdown,
  isMobile,
  activeSubDropdown,
  setActiveSubDropdown,
  handleMenuToggle
) => {
  return items.map((item, index) => {
    const isDropdownOpen = activeDropdown === index;

    if (item.submenu) {
      if (isMobile) {
        return (
          <div key={index}>
            <div
              className="cursor-pointer h-[56px] flex items-center   px-[10px] text-white hover:text-[#f6c47e]"
              onClick={() => setActiveDropdown(isDropdownOpen ? null : index)}
            >
              <span>{item.title}</span>
              <svg
                className={`w-4 h-4 ml-2 transition-transform duration-300 ${
                  isDropdownOpen ? "transform rotate-180" : ""
                }`}
                fill="none"
                stroke="white"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
            {isDropdownOpen && (
              <div className="pl-5 flex flex-col">
                {item.submenu.map((subItem, subIndex) => {
                  const isSubDropdownOpen = activeSubDropdown === subIndex;
                  return (
                    <div key={subIndex}>
                      <div
                        className="cursor-pointer pt-3 text-white hover:text-[#f6c47e]"
                        onClick={() =>
                          setActiveSubDropdown(
                            isSubDropdownOpen ? null : subIndex
                          )
                        }
                      >
                        <div className="flex items-center  ">
                          {!subItem.submenu ? (
                            <NavLink
                              to={subItem.link}
                              className="hover:text-[#F6C47E] hover:no-underline w-full block"
                              onClick={handleMenuToggle}
                            >
                              <span
                                className={`${
                                  path === subItem.link ? "text-[#F6C47E]" : ""
                                } w-[80%]`}
                              >
                                {subItem.title}
                              </span>
                            </NavLink>
                          ) : (
                            <div className="cursor-pointer text-white hover:text-[#f6c47e]">
                              {subItem.title}
                            </div>
                          )}

                          {subItem.submenu && (
                            <svg
                              className={`w-4 h-4 ml-2 transition-transform duration-300 ${
                                isSubDropdownOpen ? "transform rotate-180" : ""
                              }`}
                              fill="none"
                              stroke="white"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 5l7 7-7 7"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                      {/* Render the nested submenu if it exists */}
                      {isSubDropdownOpen && subItem.submenu && (
                        <div className="pl-2 flex flex-col">
                          {renderMenuItems(
                            subItem.submenu,
                            path,
                            setMenuOpen,
                            activeDropdown,
                            setActiveDropdown,
                            isMobile,
                            activeSubDropdown,
                            setActiveSubDropdown,
                            handleMenuToggle
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      } else {
        // Desktop dropdown remains unchanged
        return (
          <Dropdown title={item.title} key={index} trigger="hover">
            {renderMenuItems(
              item.submenu,
              path,
              setMenuOpen,
              activeDropdown,
              setActiveDropdown,
              isMobile,
              activeSubDropdown,
              setActiveSubDropdown
            )}
          </Dropdown>
        );
      }
    }

    return (
      <Nav.Item key={index}>
        <NavLink
          to={item.link}
          className="hover:text-[#F6C47E] hover:no-underline w-full block"
          onClick={handleMenuToggle}
        >
          <span
            className={`${path === item.link ? "text-[#F6C47E]" : ""} w-max`}
          >
            {item.title}
          </span>
        </NavLink>
      </Nav.Item>
    );
  });
};

const NestedNav = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null); // Track active dropdown
  const [activeSubDropdown, setActiveSubDropdown] = useState(null); // Track active submenu
  const [isMobile, setIsMobile] = useState(false); // Track if the screen size is mobile
  const location = useLocation();
  const [data, setData] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await fetchMenuData();
        setData(result);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    loadData();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024); // Consider mobile if width is <= 1024px
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Add resize event listener

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup on unmount
    };
  }, []);

  const services = data?.map((service) => ({
    title: service.title.toUpperCase(),
    link: service.sub_menus.length ? "#" : `/services/${service.short_url}`,
    submenu: service.sub_menus.length
      ? service.sub_menus.map((sub) => ({
          title: sub.title.toUpperCase(),
          link: `/services/${sub.short_url}`,
          submenu: sub.sub_menus?.length // Add nested submenu if available
            ? sub.sub_menus.map((nestedSub) => ({
                title: nestedSub.title.toUpperCase(),
                link: `/services/${nestedSub.short_url}`,
                id: nestedSub.id,
              }))
            : undefined,
          id: sub.id,
        }))
      : undefined,
    id: service.id,
  }));

  const menuItems = [
    { title: "HOME", link: "/", id: 1 },
    { title: "ABOUT US", link: "/about", id: 2 },
    {
      title: "SERVICES",
      link: "/services",
      submenu: services,
    },
    { title: "OUR PROJECTS", link: "/projects", id: 11 },
    {
      title: "GALLERY",
      link: "/gallery",
      submenu: [
        { title: "PHOTO GALLERY", link: "/photo-gallery", id: 12 },
        { title: "VIDEO GALLERY", link: "/video-gallery", id: 13 },
      ],
    },
    { title: "OUR PRODUCTS", link: "/products", id: 14 },
    {
      title: "OTHERS",
      link: "/others",
      submenu: [
        { title: "SPECIAL WORKS", link: "/special-works", id: 15 },
        { title: "BLOGS", link: "/blogs", id: 16 },
      ],
    },
    { title: "CONTACT US", link: "/contact-us", id: 17 },
  ];

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
    if (menuOpen) {
      setActiveDropdown(null);
      setActiveSubDropdown(null);
    }
  };
  return (
    <Navbar>
      <div className="flex items-center justify-between">
        <Navbar.Brand href="#">
          <NavLink to="/">
            <img src={logo} alt="Logo" className="w-[130px]" />
          </NavLink>
        </Navbar.Brand>
        <button
          className="text-gray-800 focus:outline-none lg:hidden"
          onClick={handleMenuToggle}
        >
          {menuOpen ? (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="white"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          ) : (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="white"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          )}
        </button>
        <Nav className="hidden lg:flex">
          {renderMenuItems(
            menuItems,
            location.pathname,
            setMenuOpen,
            activeDropdown,
            setActiveDropdown,
            false, // Desktop version
            activeSubDropdown,
            setActiveSubDropdown,
            handleMenuToggle
          )}
        </Nav>
      </div>
      {/* Mobile Dropdown Menu */}
      {menuOpen && (
        <Nav className="lg:hidden w-full flex pb-4 flex-col text-[#f6c47e]">
          {renderMenuItems(
            menuItems,
            location.pathname,
            setMenuOpen,
            activeDropdown,
            setActiveDropdown,
            true, // Mobile version
            activeSubDropdown,
            setActiveSubDropdown,
            handleMenuToggle
          )}
        </Nav>
      )}
    </Navbar>
  );
};

export default NestedNav;
