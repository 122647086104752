import React from 'react';
import YouTube from 'react-youtube';

const YouTubePlayer = ({ videoLink }) => {
  // Extract the video ID from the YouTube link
  const videoId = videoLink.split("youtu.be/")[1]?.split("?")[0] || "";
  
  const opts = {
    width: '100%', // Responsive width
    height: '315', // Same height as iframe
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <YouTube
      videoId={videoId}
      opts={opts}
      onError={(e) => console.error("Video Error:", e)}
    />
  );
};

export default YouTubePlayer;
