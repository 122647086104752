import React, { useEffect, useState } from "react";
import bg from "../Assets/bg3.png";
import HorizontalSlider from "../Slider/Slider";
import { NavLink } from "react-router-dom";
import { fetchAboutData } from "../Api/Api";
import { Helmet } from "react-helmet";
import MetaHelmet from "../Metadata/MetaData";
import Loader from "../Loader/Loader";

const About = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchAboutData();

        setData(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching about data:", error);
      }
    };

    loadData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      {data && <MetaHelmet metaData={data.meta_data} />}
      {data?.aboutContent && (
        <div
          className=" bg-[#F6F9FC]     bg-right-top  bg-no-repeat md:p-8"
          style={{ backgroundImage: `url(${bg})`, backgroundSize: 600 }}
        >
          <div className="w-[85%] mx-auto md:flex justify-between md:space-x-8 py-8">
            <div className="my-auto md:mr-6 md:w-[50%]">
              <h3 className="text-2xl text-[#70090A] uppercase">
                {data?.aboutContent?.title}
              </h3>
              <p className="text-sm py-2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.aboutContent?.description,
                  }}
                />
              </p>
            </div>

            <div>
              <img
                src={data?.aboutContent?.image_url}
                alt={data?.aboutContent?.image_attribute}
                className="  mt-4 md:mt-0 object-cover mx-auto w-[450px] h-[350px]"
              />
            </div>
          </div>
        </div>
      )}

      {data?.aboutContent && (
        <div className="md:flex items-center px-6 hidden md:px-0 justify-around text-white   py-4 bg-[#6C0406]">
          <div class="flex  items-center justify-center    ">
            <div class="md:w-max w-full">
              <h1 class="animate-typing overflow-hidden whitespace-nowrap border-r-4 border-r-white pr-5  md:text-2xl text-lg text-white font-medium">
                ARTISANS GROUP OF COMPANIES{" "}
                <span className="text-[#F6C47E]">SINCE 1975. </span>{" "}
              </h1>
            </div>
          </div>
          <NavLink
            to="/contact-us"
            className="relative inline-flex items-center justify-start px-4 py-2 my-2 md:my-0 overflow-hidden font-medium transition-all bg-[#F6C47E] rounded hover:bg-[#F6C47E] group active:scale-95 active:opacity-90"
          >
            <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#D8A253] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
            <span className="relative w-full text-left text-[#6C0406] transition-colors duration-300 ease-in-out group-hover:text-white">
              <span>Contact Us</span>
            </span>
          </NavLink>
        </div>
      )}
      {data?.aboutContent && (
        <div className="flex items-center px-6 md:hidden md:px-0 justify-around text-white   py-4 bg-[#6C0406]">
          <div class="flex  items-center justify-center    ">
            <div>
              <h1 className="text-lg">
                ARTISANS GROUP OF COMPANIES{" "}
                <span className="text-[#F6C47E]">SINCE 1975. </span>{" "}
              </h1>
            </div>
          </div>
          <div className="w-max">
            <NavLink
              to="/contact-us"
              className="relative inline-flex items-center justify-start px-4 py-2 my-2 md:my-0 overflow-hidden font-medium transition-all bg-[#F6C47E] rounded hover:bg-[#F6C47E] group active:scale-95 active:opacity-90"
            >
              <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#D8A253] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
              <span className="relative w-max text-left text-[#6C0406] transition-colors duration-300 ease-in-out group-hover:text-white">
                <span className="w-max">Contact Us</span>
              </span>
            </NavLink>{" "}
          </div>
        </div>
      )}

      {data?.aboutSections?.length > 0 && (
        <div className="w-[85%] mx-auto py-16">
          {data?.aboutSections?.map((section) => (
            <div
              key={section.id}
              className={`md:flex justify-between   py-4 ${
                section.sort_order % 2 === 0 ? "flex-row-reverse" : ""
              }`}
            >
              <div
                className={`  ${
                  section.sort_order % 2 === 0
                    ? "md:ml-6 lg:ml-0"
                    : "md:mr-6 lg:mr-0"
                }`}
              >
                <img
                  src={section.image_url}
                  alt={section.image_attribute}
                  className="object-cover   h-[300px]"
                />
              </div>
              <div className="my-auto md:w-1/2">
                <h3 className="text-2xl mt-4 md:mt-0 text-[#70090A] uppercase">
                  {section.title}
                </h3>
                <div
                  className="text-sm py-2"
                  dangerouslySetInnerHTML={{ __html: section.description }}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      {data?.aboutContent && (
        <div
          className=" bg-[#F6F9FC]     bg-left-top  bg-no-repeat "
          style={{ backgroundImage: `url(${bg})`, backgroundSize: 600 }}
        >
          <div className="w-[85%] mx-auto">
            <div className="flex justify-between  ">
              <div className=" py-6 mt-auto mb-16 md:w-1/2 w-[60%]">
                <h3 className="text-2xl text-[#70090A] uppercase">Our story</h3>
                <p className="text-sm py-2">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.aboutContent?.history,
                    }}
                  />
                </p>
              </div>

              <div className="md:w-1/2 w-[40%] flex items-end  ">
                <img
                  src={data?.aboutContent?.history_image_url}
                  alt={data?.aboutContent?.history_image_attribute}
                  className="   mx-auto  h-[700px]"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {data?.clients?.length > 0 && (
        <div className="bg-[#F5F5F5]">
          <div className="w-[85%] mx-auto py-8">
            <p className="text-center text-lg py-2 font-bold">Our</p>
            <h2 className="text-[#6C0406] text-center uppercase text-2xl mb-4 font-bold">
              CLIENTS
            </h2>

            <HorizontalSlider clients={data?.clients} />
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
