import React, { useEffect, useState } from "react";
import bg from "../Assets/bg3.png";
import Card from "../Cards/BlogCards";
import HorizontalSlider from "../Slider/Slider";
import { fetchBlogData } from "../Api/Api";
import MetaHelmet from "../Metadata/MetaData";
import Loader from "../Loader/Loader";

const Blog = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)
      try {
        const result = await fetchBlogData();

        setData(result);
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error("Error fetching blogs data:", error);
      }
    };

    loadData();
  }, []);
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      {data && <MetaHelmet metaData={data.meta_data} />}
      {data?.blogs?.length > 0 && (
        <div
          className=" bg-white bg-right-top  bg-no-repeat  py-8"
          style={{ backgroundImage: `url(${bg})`, backgroundSize: 500 }}
        >
          <div className="w-[85%] mx-auto my-10">
            <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-6 ">
              {data?.blogs?.map((item) => (
                <Card
                  image={item.image_url}
                  title={item.title}
                  date={item.posted_date_formated}
                  link={item.short_url}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      {data?.clients?.length > 0 && (
        <div className="bg-[#F5F5F5]">
          <div className="w-[85%] mx-auto   py-8">
            <p className="text-center text-lg py-2 font-bold">Our</p>
            <h2 className="text-[#6C0406] text-center uppercase text-2xl mb-4 font-bold">
              CLIENTS
            </h2>

            <HorizontalSlider clients={data?.clients} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Blog;
