import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import DetailSlider from "../Slider/DetailSlider";
import { fetchProjectDetailsData } from "../Api/Api";
import Loader from "../Loader/Loader";

const ServiceDetails = () => {
  const { typeId } = useParams();

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async (typeId) => {
      setIsLoading(true);
      try {
        const result = await fetchProjectDetailsData(typeId);

        setData(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching projects details data:", error);
      }
    };

    loadData(typeId);
  }, [typeId]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <div className="w-[85%] mx-auto my-14">
        {data?.project && (
          <div>
            <h3 className="text-3xl uppercase font-bold text-[#70090A]">
              {data?.project?.title}
            </h3>
            <p className="py-4 text-sm lg:w-[70%]">
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.project?.description,
                }}
              />
            </p>
            <div className="lg:flex mt-4">
              {data?.project?.images?.length > 0 && (
                <div className="lg:w-[40%]">
                  <DetailSlider
                    image={data?.project?.image_url}
                    images={data?.project?.images}
                  />
                </div>
              )}
              <div className="lg:w-[60%]">
                <div className="lg:w-[90%] mt-4 lg:mt-0 mx-auto">
                  <h2 className="text-lg ">Project Details</h2>
                  <p className="text-sm py-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.project?.project_details,
                      }}
                    />
                  </p>
                  <h2 className="text-lg py-4">Project Description</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.project?.project_description,
                    }}
                  />
                </div>
              </div>{" "}
            </div>
          </div>
        )}
        {data?.relatedProjects?.length > 0 && (
          <div className="my-10">
            <h3 className="text-2xl uppercase font-bold text-[#70090A] py-4">
              related contents
            </h3>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 ">
              {data?.relatedProjects?.map((rel) => (
                <div className="relative overflow-hidden group cursor-pointer">
                  {" "}
                  <NavLink to={`/projects/${rel.short_url}`}>
                    <img
                      src={rel.image_url}
                      alt=""
                      className="w-full h-[350px] object-cover transition-transform duration-300"
                    />{" "}
                    <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-70 transition-opacity duration-300 flex items-center justify-center">
                      <span className="text-white text-xl uppercase p-4 font-semibold">
                        {rel.title}
                      </span>
                    </div>
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceDetails;
