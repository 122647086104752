import React, {  useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const DetailSlider = ({   images }) => {
  const [selectedImage, setSelectedImage] = useState(images[0].image_url);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  // useEffect(() => {
  //   setSelectedImage(image);
  // }, [image]);

  // Settings for the vertical thumbnail slider
  const sliderSettings = {
    vertical: true,
    verticalSwiping: true,
     
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    arrows: false,
    infinite: true,
    speed: 2000,
    cssEase: "linear",
  };

  return (
    <div className="flex gap-4">
      {/* Main Image */}
      <div className="flex-1">
      <SlideshowLightbox className="w-full h-full" modalClose={"clickOutside"}>
        <img
          src={selectedImage}
          alt=" "
          className="w-full h-[370px] object-cover"
        />
        </SlideshowLightbox>
      </div>

      {/* Vertical Thumbnail Slider */}
      {images?.length > 1 && (
        <div className="relative w-24">
          <Slider {...sliderSettings} className="h-[376px]">
            {images?.map((image) => (
              <button
                key={image.id}
                className={`border-2 ${
                  selectedImage === image.image_url
                    ? "border-[#8C1A1C]"
                    : "border-transparent"
                } focus:outline-none`}
                onClick={() => handleImageClick(image.image_url)}
              >
                <img
                  src={image.image_url}
                  alt={image.title}
                  className=" h-[85px] object-cover"
                />
              </button>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default DetailSlider;
