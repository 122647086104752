import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const ServiceTab = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      {/* Tabs */}
      <div className="flex justify-center flex-wrap border-b border-gray-300 mb-8">
        {tabs?.map((tab, index) => (
          <button
            key={index}
            className={`px-6 py-2 focus:outline-none ${
              activeTab === index
                ? "bg-[#FEF4E7] border-b-2 border-[#8C1A1C]"
                : "text-gray-600"
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.title}
          </button>
        ))}
      </div>

      {/* Gallery */}
      {tabs[activeTab] && (
        <div
          key={activeTab}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4"
        >
          {tabs[activeTab].images.map((image, index) => (
            <div
              key={index}
              className="relative overflow-hidden group cursor-pointer"
            >
              <NavLink to={`/projects/${image.id}`}>
                <img
                  src={image.src}
                  alt={image.alt}
                  className="w-full h-[350px] object-cover transition-transform duration-300  "
                />
                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-70 transition-opacity duration-300 flex items-center justify-center">
                  <span className="text-white text-xl uppercase p-4 font-semibold">
                    {image.alt}
                  </span>
                </div>
              </NavLink>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ServiceTab;
