import React from "react";
import { NavLink } from "react-router-dom";

const Card = ({ image, date, title, link }) => {
  return (
    <div className="  border  border-gray-300 overflow-hidden shadow-lg px-4 py-4 ">
      <NavLink to={`/blog/${link}`}>
        <img
          className="w-full overflow-hidden transform transition duration-500 hover:scale-105 object-cover h-[270px]"
          src={image}
          alt={title}
        />
        <div className="h-24">
          <p className="text-gray-700 text-sm mt-2">{date}</p>
          <hr className="my-1" />
          <div className="font-bold text-lg py-2 ">{title}</div>
        </div>{" "}
      </NavLink>
    </div>
  );
};

export default Card;
