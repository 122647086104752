import React, { useEffect, useState } from "react";
import bg from "../Assets/bg3.png";
import TabbedGallery from "../Tab/Tab";
import Card from "../Cards/BlogCards";
import { NavLink, useParams } from "react-router-dom";
import { fetchServiceData } from "../Api/Api";
import MetaHelmet from "../Metadata/MetaData";
import Loader from "../Loader/Loader";

const Services = () => {
  const { serviceId } = useParams();

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async (serviceId) => {
      setIsLoading(true);
      try {
        const result = await fetchServiceData(serviceId);

        setData(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching service data:", error);
      }
    };

    loadData(serviceId);
  }, [serviceId]);

  let tabs = [];
  if (data?.galleries) {
    tabs = Object.keys(data.galleries).map((key) => ({
      title: key,
      images: data.galleries[key].map((item) => ({
        src: item.image_url,
        alt: item.title,
      })),
    }));
  }
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {data && <MetaHelmet metaData={data.meta_data} />}
      {data?.serviceContent && (
        <div
          className=" bg-[#F6F9FC]     bg-right-top  bg-no-repeat md:p-8"
          style={{ backgroundImage: `url(${bg})`, backgroundSize: 600 }}
        >
          <div className="w-[85%] mx-auto md:flex justify-between md:space-x-8 py-8">
            <div className="my-auto md:mr-6 md:w-[50%]">
              <h3 className="text-2xl text-[#70090A] uppercase">
                {" "}
                {data?.serviceContent?.title}
              </h3>
              <p className="text-sm py-2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.serviceContent?.description,
                  }}
                />
              </p>
            </div>

            <div>
              <img
                src={data?.serviceContent?.image_url}
                alt={data?.serviceContent?.image_attribute}
                className="  mt-4 md:mt-0  object-cover mx-auto w-[450px] h-[350px]"
              />
            </div>
          </div>
        </div>
      )}
      {data?.serviceContent && (
        <div className="md:flex items-center hidden px-6  md:px-0 justify-around text-white   py-4 bg-[#6C0406]">
          <div class="flex  items-center justify-center    ">
            <div class="w-max">
              <h1 class="animate-typing overflow-hidden whitespace-nowrap border-r-4 border-r-white pr-5 text-2xl text-white font-medium">
                ARTISANS GROUP OF COMPANIES{" "}
                <span className="text-[#F6C47E]">SINCE 1975. </span>{" "}
              </h1>
            </div>
          </div>
          <NavLink
            to="/contact-us"
            className="relative inline-flex items-center justify-start px-4 py-2 my-2 md:my-0 overflow-hidden font-medium transition-all bg-[#F6C47E] rounded hover:bg-[#F6C47E] group active:scale-95 active:opacity-90"
          >
            <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#D8A253] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
            <span className="relative w-full text-left text-[#6C0406] transition-colors duration-300 ease-in-out group-hover:text-white">
              <span>Contact Us</span>
            </span>
          </NavLink>
        </div>
      )}

      {data?.serviceContent && (
        <div className="flex items-center px-6 md:hidden md:px-0 justify-around text-white   py-4 bg-[#6C0406]">
          <div class="flex  items-center justify-center    ">
            <div>
              <h1 className="text-lg">
                ARTISANS GROUP OF COMPANIES{" "}
                <span className="text-[#F6C47E]">SINCE 1975. </span>{" "}
              </h1>
            </div>
          </div>
          <div className="w-max">
            <NavLink
              to="/contact-us"
              className="relative inline-flex items-center justify-start px-4 py-2 my-2 md:my-0 overflow-hidden font-medium transition-all bg-[#F6C47E] rounded hover:bg-[#F6C47E] group active:scale-95 active:opacity-90"
            >
              <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#D8A253] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
              <span className="relative w-max text-left text-[#6C0406] transition-colors duration-300 ease-in-out group-hover:text-white">
                <span className="w-max">Contact Us</span>
              </span>
            </NavLink>{" "}
          </div>
        </div>
      )}

      <div className="mx-auto   w-[85%] pb-10 pt-24 ">
        <h3 className="text-2xl text-[#70090A] uppercase">
          {data?.service?.title}
        </h3>
        <p className="text-sm py-2">
          <div
            dangerouslySetInnerHTML={{
              __html: data?.service?.description,
            }}
          />
        </p>
      </div>

      {tabs.length > 0 && (
        <div className="w-[85%] mx-auto pb-14">
          <TabbedGallery tabs={tabs} />
        </div>
      )}
      {data?.blogs?.length > 0 && (
        <div className="w-[85%] mx-auto my-10">
          <p className="text-center text-lg py-2 font-bold">Our</p>
          <h2 className="text-[#6C0406] text-center uppercase text-2xl mb-4 font-bold">
            BLOGS
          </h2>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 ">
            {data?.blogs?.map((item) => (
              <Card
                image={item.image_url}
                title={item.title}
                date={item.posted_date_formated}
                link={item.short_url}
              />
            ))}
          </div>
          <div className="text-end w-full mt-4">
            <NavLink
              to="/blogs"
              className="relative inline-flex items-center justify-start px-4 py-2 my-3 overflow-hidden font-medium transition-all bg-[#F6C47E] rounded hover:bg-[#F6C47E] group active:scale-95 active:opacity-90"
            >
              <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#D8A253] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
              <span className="relative w-full text-left text-[#6C0406] transition-colors duration-300 ease-in-out group-hover:text-white">
                <span> View All</span>
              </span>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;
