import React from "react";
import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaPhone,
  FaWhatsapp,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";

import { NavLink } from "react-router-dom";

const Footer = ({ data, tag }) => {
  const year = new Date().getFullYear();

  return (
    <footer>
      <div
        dangerouslySetInnerHTML={{
          __html: tag?.footer_tag,
        }}
      />
      <div
        style={{ backgroundImage: `url(${data.bg})` }}
        className="bg-no-repeat relative   bg-cover"
      >
        <div className="w-[85%] mx-auto">
          <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-8  ">
            <div className="lg:py-16 py-4 text-white">
              <NavLink to="/">
                <img
                  src={data.logo}
                  alt=""
                  className="rounded-2xl w-[80%] cursor-pointer"
                />{" "}
              </NavLink>
              <div className="flex items-center my-3">
                <FaPhone />{" "}
                <a href={`tel:${data.phone}`} className="ml-2 mr-6">
                  {data.phone}
                </a>
              </div>
              <div className="flex items-center my-3">
                <FaEnvelope />{" "}
                <a href={`mailto:${data.email}`} className="ml-2 mr-6">
                  {data.email}
                </a>
              </div>
            </div>

            <div className="lg:py-16 py-4 text-white">
              <p className="font-bold uppercase mb-1">useful links</p>
              <NavLink to="/" className="block text-xs uppercase py-1 ">
                <span className="hover:underline ">Home </span>
              </NavLink>
              <NavLink
                to="/about"
                className="block text-xs py-1 uppercase hover:underline"
              >
                <span className="hover:underline ">about us</span>
              </NavLink>
              <NavLink
                to="/products"
                className="block text-xs py-1 uppercase hover:underline"
              >
                <span className="hover:underline "> our products</span>
              </NavLink>
              <NavLink
                to="/photo-gallery"
                className="block text-xs py-1 uppercase hover:underline"
              >
                <span className="hover:underline ">gallery</span>
              </NavLink>
              <NavLink
                to="/special-works"
                className="block text-xs py-1 uppercase hover:underline"
              >
                <span className="hover:underline ">special works </span>
              </NavLink>
              <NavLink
                to="/blogs"
                className="block text-xs uppercase py-1 hover:underline"
              >
                <span className="hover:underline ">blogs </span>
              </NavLink>
              <NavLink
                to="/contact-us"
                className="block text-xs uppercase py-1 hover:underline"
              >
                <span className="hover:underline "> contact us </span>
              </NavLink>
            </div>

            <div className="lg:py-16 py-4 text-white">
              <p className="font-bold uppercase mb-1">what we offer</p>
              {data?.menus?.map((menu) => (
                <NavLink
                key={menu.short_url}
                  to={`services/${menu.short_url}`}
                  className="block text-xs uppercase py-1 hover:underline"
                >
                  <span className="hover:underline ">{menu.title} </span>
                </NavLink>
              ))}
            </div>

            <div className="flex items-start lg:py-16  pb-4 text-xl text-white space-x-4">
              <a
                href={`https://web.whatsapp.com/send?phone=${data.whatsapp}&text=Hello`}
                target="_blank"
                className="cursor-pointer"
              >
                <FaWhatsapp />
              </a>
              <a href={data.insta} target="_blank" className="cursor-pointer">
                {" "}
                <FaInstagram />
              </a>
              <a href={data.fb} target="_blank" className="cursor-pointer">
                {" "}
                <FaFacebook />
              </a>
              <a href={data.x} target="_blank" className="cursor-pointer">
                {" "}
                <FaXTwitter />
              </a>
              <a href={data.utube} target="_blank" className="cursor-pointer">
                {" "}
                <FaYoutube />
              </a>
            </div>
          </div>
        </div>

        <hr className="m-0" />

        <div className="w-[85%] flex justify-between items-center mx-auto text-white">
          <p className="text-xs py-3 w-[50%]">{data.copy}</p>
          <p className="text-xs py-3">{data.design}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
