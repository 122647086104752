import axios from "axios";
const base_url = "https://ananthanachary.com/admin/api/";

export const fetchHomeData = async () => {
  try {
    const response = await axios.get(`${base_url}home-data`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching home data:", error);
    throw error;
  }
};

export const fetchAboutData = async () => {
  try {
    const response = await axios.get(`${base_url}about-data`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching about data:", error);
    throw error;
  }
};

export const fetchServiceData = async (type) => {
  try {
    const response = await axios.get(`${base_url}service/${type}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching service   data:", error);
    throw error;
  }
};

export const fetchBlogData = async () => {
  try {
    const response = await axios.get(`${base_url}blogs`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching blogs data:", error);
    throw error;
  }
};

export const fetchMenuData = async () => {
  try {
    const response = await axios.get(`${base_url}service-menu`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching menu data:", error);
    throw error;
  }
};

export const fetchProjectData = async () => {
  try {
    const response = await axios.get(`${base_url}projects`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching projects data:", error);
    throw error;
  }
};

export const fetchProjectDetailsData = async (type) => {
  try {
    const response = await axios.get(`${base_url}project/${type}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching project details data:", error);
    throw error;
  }
};

export const fetchPhotoGalleryData = async () => {
  try {
    const response = await axios.get(`${base_url}photo-gallery`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching photo gallery data:", error);
    throw error;
  }
};

export const fetchVideoGalleryData = async () => {
  try {
    const response = await axios.get(`${base_url}video-gallery`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching video gallery data:", error);
    throw error;
  }
};

export const fetchProductData = async () => {
  try {
    const response = await axios.get(`${base_url}products`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching products data:", error);
    throw error;
  }
};

export const fetchProductDetailsData = async (type) => {
  try {
    const response = await axios.get(`${base_url}product/${type}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching product details data:", error);
    throw error;
  }
};

export const fetchWorksData = async () => {
  try {
    const response = await axios.get(`${base_url}special-works`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching special works data:", error);
    throw error;
  }
};

export const fetchContactData = async () => {
  try {
    const response = await axios.get(`${base_url}contact-us`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching contact data:", error);
    throw error;
  }
};

export const fetchSiteData = async () => {
  try {
    const response = await axios.get(`${base_url}site-info`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching site info data:", error);
    throw error;
  }
};

export const sendFormData = async (value) => {
  try {
    const response = await axios.post(`${base_url}contact-us-store`, value);
    return response.data.message;
  } catch (error) {
    console.error("Error sending form data:", error);
    throw error;
  }
};

export const fetchBooksData = async () => {
  try {
    const response = await axios.get(`${base_url}books`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching books data:", error);
    throw error;
  }
};
