import React from "react";
import w from "../Assets/whatsapp.png";
import ReadMoreReadLess from "react-read-more-read-less";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import { useLocation } from "react-router-dom";

const ProductCard = ({ image, title, description, link ,mob_link}) => {
  const { pathname } = useLocation();
  console.log(pathname);
  return (
    <div className="rounded-xl shadow-md">
      <div className="h-44">
        {/* SlideshowLightbox for lightbox functionality */}
        <SlideshowLightbox className="w-full h-full" modalClose={"clickOutside"}>
          <img
            className="w-full h-full object-contain rounded-t-xl transition-all duration-700 hover:scale-95 cursor-pointer"
            src={image}
            alt={title}
          />
        </SlideshowLightbox>
      </div>
      <div className="px-10 py-5 relative bg-[#F5F5F5] min-h-[150px]">
        <div className="font-bold text-base text-center mb-2">{title}</div>
        {description && (
          <div className="text-gray-700 text-sm text-center mb-2">
            <ReadMoreReadLess
              charLimit={70}
              readMoreText="Read more"
              readLessText="Read less"
              readMoreClassName="text-blue-500 cursor-pointer"
              readLessClassName="text-blue-500 cursor-pointer"
            >
              {description}
            </ReadMoreReadLess>
          </div>
        )}
        {/* Show desktop link on larger screens */}
        <a
          className="absolute bottom-[-25px] left-[40%] z-50 hidden sm:block"
          target="_blank"
          href={link}
        >
          <img src={w} alt="WhatsApp" />
        </a>
        {/* Show mobile link on smaller screens */}
        <a
          className="absolute bottom-[-40px] left-[40%] z-50 block sm:hidden"
          target="_blank"
          href={mob_link}
        >
          <img src={w} alt="WhatsApp" className="w-[75px] h-[75px]" />
        </a>
      </div>
    </div>
  );
};

export default ProductCard;
