import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";

const StatsSection = ({ keyFeatures }) => {
  const [inView, setInView] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setInView(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className="md:w-[30%] bg-[#6C0406] lg:block md:flex items-center flex-col justify-center p-6 text-[#F6C47E]"
    >
      {keyFeatures?.map((feature) => (
        <div
          key={feature.id}
          className="text-center p-8 border-2 border-[#8C1A1C] rounded-md mb-3 last:mb-0"
        >
          {inView && (
            <CountUp
              start={0}
              end={parseInt(feature.count, 10)}
              duration={3}
              suffix={feature.symbol}
              className="text-2xl font-semibold"
            />
          )}
          <p className="text-sm uppercase">{feature.title.toLowerCase()}</p>
        </div>
      ))}
    </div>
  );
};

export default StatsSection;
