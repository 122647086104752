import React, { useEffect, useState } from "react";
import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaPhone,
  FaWhatsapp,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { IoIosCloseCircle } from "react-icons/io";
import { fetchBooksData } from "../Api/Api";

const TopNav = ({ data, tag }) => {
  const [buy, setBuy] = useState(false);

  const [booksData, setBooksData] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await fetchBooksData();

        setBooksData(result);
      } catch (error) {
        console.error("Error book data:", error);
      }
    };

    loadData();
  }, []);

  const handleClick = (event) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to open this link in a new tab?"
    );
    if (!userConfirmed) {
      event.preventDefault();
    }
  };
  return (
    <header className="relative">
      <div
        dangerouslySetInnerHTML={{
          __html: tag?.header_tag,
        }}
      />
      <div className="lg:flex items-center justify-between w-[85%] mx-auto">
        <div className="text-white   flex flex-col  lg:flex-row items-center justify-center py-4">
          <div className="flex items-center">
            <FaPhone />{" "}
            <a href={`tel:${data.phone}`} className="ml-2 mr-6">
              {data.phone}
            </a>
          </div>
          <div className="flex items-center pt-2 lg:pt-0">
            <FaEnvelope />{" "}
            <a
              href={`mailto:${data.email}`}
              target="_blank"
              className="ml-2 mr-6"
            >
              {data.email}
            </a>
          </div>
        </div>
        <div className="flex items-center justify-center text-white text-lg space-x-4 pb-4 pt-0 md:pt-4">
          <a
            href={`https://web.whatsapp.com/send?phone=${data.whatsapp}`}
            target="_blank"
            className="cursor-pointer"
          >
            <FaWhatsapp />
          </a>
          <a href={data.insta} target="_blank" className="cursor-pointer">
            {" "}
            <FaInstagram />
          </a>
          <a href={data.fb} target="_blank" className="cursor-pointer">
            {" "}
            <FaFacebook />
          </a>
          <a href={data.x} target="_blank" className="cursor-pointer">
            {" "}
            <FaXTwitter />
          </a>
          <a href={data.utube} target="_blank" className="cursor-pointer">
            {" "}
            <FaYoutube />
          </a>

          <button
            onClick={() => setBuy(!buy)}
            className="relative text-sm inline-flex items-center justify-start px-4 py-2 lg:my-0 overflow-hidden font-medium transition-all bg-[#F6C47E] rounded hover:bg-[#F6C47E] group active:scale-95 active:opacity-90"
          >
            <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#D8A253] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
            <span className="relative w-full text-left text-[#6C0406] transition-colors duration-300 ease-in-out group-hover:text-white">
              Buy Now
            </span>
          </button>
        </div>
        {buy && booksData?.books?.length > 0 && (
          <div className="absolute  md:top-[55px] bg-[#DDD8D8F2] bg-opacity-95 z-[999] h-[350px] w-full  right-0  ">
            <div className="w-[85%] mx-auto flex space-x-8 p-8 relative">
              {booksData?.books?.map((book) => (
                <div key={book.id} className="text-center">
                  <img
                    src={book.image_url}
                    alt={book.image_attribute}
                    className="w-[200px] md:h-[250px] h-[200px] object-cover"
                  />
                  <div className="w-full mt-2">
                    <a
                      href={book.purchase_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleClick}
                      className="relative inline-flex items-center justify-start px-4 py-2 my-3 overflow-hidden font-medium transition-all bg-[#F6C47E] rounded hover:bg-[#F6C47E] group active:scale-95 active:opacity-90"
                    >
                      <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#D8A253] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
                      <span className="relative w-full text-left text-[#6C0406] transition-colors duration-300 ease-in-out group-hover:text-white">
                        <span>Buy Now</span>
                      </span>
                    </a>
                  </div>
                </div>
              ))}
              <div>
                <IoIosCloseCircle
                  onClick={() => setBuy(false)}
                  className="text-2xl cursor-pointer text-black absolute right-0 top-3"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default TopNav;
