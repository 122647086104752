import React, { useState } from "react";
import YouTubePlayer from "../VideoPlayer/VideoPlayer";
 
const VideoTabGallery = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      {/* Tabs */}
      <div className="flex justify-center flex-wrap border-b border-gray-300 mb-8">
        {tabs?.map((tab, index) => (
          <button
            key={index}
            className={`px-6 py-2 focus:outline-none ${
              activeTab === index
                ? "bg-[#FEF4E7]   border-b-2 border-[#8C1A1C]"
                : "text-gray-600"
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.title}
          </button>
        ))}
      </div>

      {/* Gallery */}

      {tabs[activeTab] && (
        <div className="grid grid-cols-1 md:grid-cols-2  gap-4 mt-4">
          {tabs[activeTab].videos.map((video, index) => (
            <div key={index} className="relative overflow-hidden group">
              <div className="w-full aspect-w-16 aspect-h-9 py-1">
              <YouTubePlayer videoLink={video.url}/>
                
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VideoTabGallery;
