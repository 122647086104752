import React, { useEffect, useState } from "react";
import { FaInstagram, FaLinkedinIn, FaXTwitter } from "react-icons/fa6";
import { NavLink, useParams } from "react-router-dom";
import bg from "../Assets/bg3.png";
import Card from "../Cards/BlogCards";
import { fetchBlogData } from "../Api/Api";
import MetaHelmet from "../Metadata/MetaData";
import Loader from "../Loader/Loader";

const BlogDetail = () => {
  const { blogId } = useParams();

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)
      try {
        const result = await fetchBlogData();

        setData(result);
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error("Error fetching blogs data:", error);
      }
    };

    loadData();
  }, []);

  if (isLoading) {
    return <Loader/>;
  }

  const blog = data?.blogs?.find((blog) => blog.short_url === blogId);

  if (!blog) {
    return <div>Blog not found</div>;
  }

  const otherBlogs = data?.blogs
    ?.filter((b) => b.short_url !== blogId)
    .slice(0, 3);

  return (
    <div>
      {data && <MetaHelmet metaData={data.meta_data} />}
      <div
        className=" bg-white bg-right-top  bg-no-repeat  py-8"
        style={{ backgroundImage: `url(${bg})`, backgroundSize: 500 }}
      >
        <div className="w-[85%] mx-auto ">
          <h1 className="text-2xl font-bold text-[#70090A] uppercase my-10">
            {blog.title}
          </h1>
          <div className="md:flex justify-end items-center text-gray-800">
            <p className="text-gray-800   text-sm mt-[8px] md:px-4">
              {blog.posted_date_formated}
            </p>
            <p className="text-gray-800   text-sm md:px-4">
              {blog.reading_time}
            </p>
            <p className="text-gray-800   text-sm md:px-4 md:py-0 py-2">
              Share
            </p>
            <div className="flex items-center space-x-4 mt-[8px]">
              <a
                href="/https://in.linkedin.com/"
                target="_blank"
                rel="noreferrer"
                className="hover:text-gray-400"
              >
                <FaLinkedinIn />
              </a>
              <a
                href="https://www.instagram.com/accounts/login/?hl=en"
                target="_blank"
                rel="noreferrer"
                className="hover:text-gray-400"
              >
                <FaInstagram />
              </a>

              <a
                href="https://x.com/?lang=en"
                target="_blank"
                rel="noreferrer"
                className="hover:text-gray-400"
              >
                <FaXTwitter />
              </a>
            </div>
          </div>
        </div>

        <hr className="mb-8" />
        <div className="w-[85%] mx-auto  ">
          <img
            className="w-full md:h-[500px] h-[300px]  object-cover"
            src={blog.image_url}
            alt={blog.title}
          />

          <div className="mt-10">
            <div
              dangerouslySetInnerHTML={{
                __html: blog.description,
              }}
            />
          </div>
        </div>

        <div className="w-[85%] mx-auto my-10">
          <p className="text-center text-lg py-2 font-bold">Our</p>
          <h2 className="text-[#6C0406] text-center uppercase text-2xl mb-4 font-bold">
            BLOGS
          </h2>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 ">
            {otherBlogs.map((item) => (
              <Card
                image={item.image_url}
                title={item.title}
                date={item.posted_date_formated}
                link={item.short_url}
              />
            ))}
          </div>
          <div className="text-end w-full mt-4">
            <NavLink
              to="/blogs"
              className="relative inline-flex items-center justify-start px-4 py-2 my-3 overflow-hidden font-medium transition-all bg-[#F6C47E] rounded hover:bg-[#F6C47E] group active:scale-95 active:opacity-90"
            >
              <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#D8A253] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
              <span className="relative w-full text-left text-[#6C0406] transition-colors duration-300 ease-in-out group-hover:text-white">
                <span> View All</span>
              </span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
