import React, { useEffect, useState } from "react";
import capbg from "../Assets/bannerText.png";
import bg3 from "../Assets/bg3.png";
import Card from "../Cards/BlogCards";
import HorizontalSlider from "../Slider/Slider";
import { NavLink } from "react-router-dom";
import StatsSection from "../Count/Count";
import { fetchHomeData } from "../Api/Api";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MetaHelmet from "../Metadata/MetaData";
import Loader from "../Loader/Loader";
import LazyLoad from "react-lazyload";
import YouTubePlayer from "../VideoPlayer/VideoPlayer";
 
 
const Home = ({ bg }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchHomeData();

        setData(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching home data:", error);
      }
    };

    loadData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      {data && <MetaHelmet metaData={data.meta_data} />}

      {data?.homeBanner.length > 0 && (
        <div className="h-screen-minus-114 overflow-hidden">
          {/* <img
          src={data?.homeBanner?.banner_url}
          alt=""
          className="w-full relative h-screen object-cover"
        /> */}

          <div className="carousel-wrapper relative">
            <Carousel
              showThumbs={false} // Hide thumbnails
              autoPlay // Auto play the carousel
              infiniteLoop // Loop infinitely
              stopOnHover={false}
              showIndicators={false}
              showStatus={false} // Hide status indicator
              showArrows={false} // Hide navigation arrows
              interval={3000} // Time between slides (3 seconds)
              swipeable={true} // Enable swipe for touch devices
              emulateTouch={true} // Enables smooth touch/swipe on desktop as well
            >
              {data?.homeBanner?.map((banner, index) => (
                <div key={index} className="relative">
                  <LazyLoad height={200}  >
                  <img
                    src={banner?.banner_url}
                    alt={banner?.title}
                    className="w-full h-screen-minus-114 object-cover"
                     
                  /></LazyLoad>
                  {/* Title and description overlay */}
                  <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white px-4">
                    <h1 className=" text-lg md:text-xl mb-4">
                      {banner?.title}
                    </h1>
                    <div
                      className="text-4xl md:text-6xl font-bold uppercase"
                      dangerouslySetInnerHTML={{
                        __html: banner?.description,
                      }}
                    />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      )}

      {data?.homeBanner.length > 0 && (
        <div className=" w-[60%] mx-auto hidden md:block">
          <div
            className=" border border-gray-500    absolute md:bottom-[-150px] top-[70%] md:top-auto  bg-cover md:w-[60%] w-[90%] md:left-auto left-[5%]  bg-no-repeat "
            style={{ backgroundImage: `url(${capbg})` }}
          >
            <div className="bg-white  p-8 bg-opacity-65">
              <h3 className="text-3xl text-[#70090A] text-center mb-3">
                ARTISANS GROUP OF COMPANIES
              </h3>
              <p className="text-sm text-center text-[#4E4E4E]">
                {/* <div
                dangerouslySetInnerHTML={{
                  __html: data?.homeBanner?.description,
                }}
              /> */}{" "}
                Artisans group of companies has carved a respectable name by
                virtue of our proven track record as traditional temple
                architects and integrity we built through three decades long
                works. We have understood the immense power of artifacts,
                archetypal of Kerala’s splendid tradition and artisans talents
                in the course of our work as temple architects. This has led us
                to embark on unique commercial ventures with dedicated focus on
                three verticals. Each entity would be an open platform for every
                artisan across the state to commercialize their talents and
                lovers of artifacts to deal with us.
              </p>
            </div>
          </div>
        </div>
      )}

      {data?.homeBanner.length > 0 && (
        <div className="md:flex items-center px-6 hidden md:px-0 justify-around text-white md:mt-[230px] py-4 bg-[#6C0406]">
          <div class="flex  items-center justify-center    ">
            <div class="w-max">
              <h1 class="animate-typing overflow-hidden whitespace-nowrap border-r-4 border-r-white pr-5  text-2xl   text-white font-medium">
                ARTISANS GROUP OF COMPANIES{" "}
                <span className="text-[#F6C47E]">SINCE 1975. </span>{" "}
              </h1>
            </div>
          </div>
          <NavLink
            to="/contact-us"
            className="relative inline-flex items-center justify-start px-4 py-2 my-2 md:my-0 overflow-hidden font-medium transition-all bg-[#F6C47E] rounded hover:bg-[#F6C47E] group active:scale-95 active:opacity-90"
          >
            <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#D8A253] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
            <span className="relative w-full text-left text-[#6C0406] transition-colors duration-300 ease-in-out group-hover:text-white">
              <span>Contact Us</span>
            </span>
          </NavLink>
        </div>
      )}

      {data?.homeBanner.length > 0 && (
        <div className="flex items-center px-6 md:hidden md:px-0 justify-around text-white   py-4 bg-[#6C0406]">
          <div class="flex  items-center justify-center    ">
            <div>
              <h1 className="text-lg">
                ARTISANS GROUP OF COMPANIES{" "}
                <span className="text-[#F6C47E]">SINCE 1975. </span>{" "}
              </h1>
            </div>
          </div>
          <div className="w-max">
            <NavLink
              to="/contact-us"
              className="relative inline-flex items-center justify-start px-4 py-2 my-2 md:my-0 overflow-hidden font-medium transition-all bg-[#F6C47E] rounded hover:bg-[#F6C47E] group active:scale-95 active:opacity-90"
            >
              <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#D8A253] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
              <span className="relative w-max text-left text-[#6C0406] transition-colors duration-300 ease-in-out group-hover:text-white">
                <span className="w-max">Contact Us</span>
              </span>
            </NavLink>{" "}
          </div>
        </div>
      )}

      {data?.latestProjects?.length > 0 && (
        <div className="w-[85%] mx-auto">
          <p className=" text-lg text-center mt-24">See our work</p>
          <h2 className=" text-center text-2xl font-bold uppercase my-3">
            Latest <span className="text-[#6C0406]"> projects</span>{" "}
          </h2>
          <div className="md:flex mb-10  ">
            {data?.latestProjects?.map((image, index) => (
              <div
                key={index}
                className="relative  group overflow-hidden flex transition-all duration-700  basis-1/3 hover:basis-2/3  ease-in-out"
              >
                {/* Image */}
               
                <img
                  src={image.image_url}
                  alt={`${index + 1}`}
                   
                  className="w-full h-[250px] md:h-[400px] object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-105"
                />
 
                {/* Black Overlay */}
                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-70 transition-opacity duration-300"></div>

                {/* Text */}
                <NavLink to={`/projects/${image.short_url}`}>
                  <span className="absolute inset-0 flex items-center justify-center text-xl font-bold text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 transform p-2 animate-bounce">
                    {image.title}
                  </span>
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      )}

      {data?.whatWeOffer?.length > 0 && (
        <div
          className=" bg-no-repeat  bg-cover mt-4  "
          style={{ backgroundImage: `url(${bg})` }}
        >
          <div className="w-[85%] mx-auto">
            <div className="md:w-[60%] bg-[#6C0406] text-white p-6">
              <h3 className="text-2xl font-bold uppercase py-6">
                What We Offer
              </h3>
              <div className="md:flex flex-wrap justify-between mb-4">
                {data?.whatWeOffer?.map((offer) => (
                  <div key={offer.id} className="md:w-[45%] mb-4">
                    <h4 className="text-lg font-semibold">{offer.title}</h4>
                    <div
                      className="text-sm"
                      dangerouslySetInnerHTML={{ __html: offer.description }}
                    ></div>
                    <NavLink
                      to={offer.button_url}
                      className="relative inline-flex items-center justify-start py-2 px-4 my-3 overflow-hidden font-medium transition-all bg-white rounded hover:bg-[#F6C47E] group active:scale-95 active:opacity-90"
                    >
                      <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#D8A253] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
                      <span className="relative w-full text-left text-[#6C0406] transition-colors duration-300 ease-in-out group-hover:text-white">
                        {offer.button_text}
                      </span>
                    </NavLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {data?.prestigiousProjectContent && (
        <div className="md:flex">
          <div
            className=" md:w-[70%] bg-no-repeat  bg-cover p-14 lg:flex"
            style={{ backgroundImage: `url(${bg3})` }}
          >
            <img
              src={data?.prestigiousProjectContent?.image_url}
              alt=""
              className="md:mx-auto"
            />
            <div className="my-auto text-center lg:mr-16 py-3 ">
              <p className="py-3 font-bold">
                {data?.prestigiousProjectContent?.title}
              </p>
              <h3 className="text-[#6C0406] uppercase text-2xl font-bold">
                {data?.prestigiousProjectContent?.sub_title}
              </h3>
              <NavLink
                to={data?.prestigiousProjectContent?.button_url}
                className="relative inline-flex items-center justify-start px-4 py-2 my-3 overflow-hidden font-medium transition-all bg-[#F6C47E] rounded hover:bg-[#F6C47E] group active:scale-95 active:opacity-90"
              >
                <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#D8A253] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
                <span className="relative w-full text-left text-[#6C0406] transition-colors duration-300 ease-in-out group-hover:text-white">
                  <span> {data?.prestigiousProjectContent?.button_text} </span>
                </span>
              </NavLink>
            </div>
          </div>
          {data?.keyFeatures?.length > 0 && (
            <StatsSection keyFeatures={data?.keyFeatures} />
          )}
        </div>
      )}

      {data?.homeAbout && (
        <div className="mt-14 w-[85%] mx-auto">
          <p className="text-center text-sm py-2 font-bold">
            {data?.homeAbout?.title}
          </p>
          <h2 className="text-[#6C0406] text-center uppercase text-2xl font-bold">
            {data?.homeAbout?.sub_title}
          </h2>
          <p className="text-sm py-3">
            <div
              className="text-sm"
              dangerouslySetInnerHTML={{ __html: data?.homeAbout?.description }}
            ></div>
          </p>
        </div>
      )}
      {data?.homeAbout && (
        <div
          style={{ backgroundImage: `url(${bg3})` }}
          className="bg-no-repeat relative bg-cover my-4"
        >
          <div className="absolute inset-0 bg-[#E2DCD133] opacity-70"></div>
          <div className="relative z-10 w-[85%] mx-auto lg:flex">
          <div className="w-full aspect-w-16 aspect-h-9 py-8">
  <LazyLoad height={200}  once placeholder={<div>Loading video...</div>}>
    {data?.homeAbout?.video_link && <YouTubePlayer videoLink={data.homeAbout.video_link} />}
  </LazyLoad>
</div>

            <div className="my-auto lg:ml-10">
              <p className="text-sm font-bold">
                {data?.homeAbout?.video_title}
              </p>
              <h3 className="text-lg text-[#6C0406] font-bold py-2">
                {data?.homeAbout?.video_sub_title}
              </h3>
              <p className="text-sm">
                <div
                  className="text-sm"
                  dangerouslySetInnerHTML={{
                    __html: data?.homeAbout?.video_description,
                  }}
                ></div>
              </p>

              <NavLink
                to={data?.homeAbout?.button_url}
                className="relative inline-flex items-center justify-start px-4 py-2 my-3 overflow-hidden font-medium transition-all bg-[#F6C47E] rounded hover:bg-[#F6C47E] group active:scale-95 active:opacity-90"
              >
                <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#D8A253] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
                <span className="relative w-full text-left text-[#6C0406] transition-colors duration-300 ease-in-out group-hover:text-white">
                  <span> {data?.homeAbout?.button_text} </span>
                </span>
              </NavLink>
            </div>
          </div>
        </div>
      )}
      {data?.blogs?.length > 0 && (
        <div className="w-[85%] mx-auto my-10">
          <p className="text-center text-lg py-2 font-bold">Our</p>
          <h2 className="text-[#6C0406] text-center uppercase text-2xl mb-4 font-bold">
            BLOGS
          </h2>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 ">
            {data?.blogs?.map((item) => (
              <div key={item.id}>
                 <Card
                image={item.image_url}
                title={item.title}
                date={item.posted_date_formated}
                link={item.short_url}
              />
              </div>
             
            ))}
          </div>
          <div className="text-end w-full mt-4">
            <NavLink
              to="/blogs"
              className="relative inline-flex items-center justify-start px-4 py-2 my-3 overflow-hidden font-medium transition-all bg-[#F6C47E] rounded hover:bg-[#F6C47E] group active:scale-95 active:opacity-90"
            >
              <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#D8A253] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
              <span className="relative w-full text-left text-[#6C0406] transition-colors duration-300 ease-in-out group-hover:text-white">
                <span> View All</span>
              </span>
            </NavLink>
          </div>
        </div>
      )}
      {data?.clients?.length > 0 && (
        <div className="bg-[#F5F5F5]">
          <div className="w-[85%] mx-auto mt-10 py-6">
            <p className="text-center text-lg py-2 font-bold">Our</p>
            <h2 className="text-[#6C0406] text-center uppercase text-2xl mb-4 font-bold">
              CLIENTS
            </h2>

            <HorizontalSlider clients={data?.clients} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
